const funcs = [
  Math.acos,
  Math.acosh,
  Math.asin,
  Math.atan,
  Math.atanh,
  Math.cos,
  Math.cosh,
  Math.exp,
  Math.expm1,
  Math.fround,
  Math.log,
  Math.log10,
  Math.sin,
  Math.sinh,
  Math.sqrt,
  Math.tan,
  Math.tanh,
];

const constants = [
  -1,
  0,
  1,
  Math.E,
  Math.PI,
  Math.SQRT2,
  1e300,
  -1e300,
  1e-300,
  1e-300,
];

export function getMath(): object {
  const calcs = {};
  for (const f of funcs) {
    if (f) {
      const fCalcs = (calcs[f.name] ??= {});

      for (const c of constants) {
        // We skip NaNs mostly to reduce payload size without losing
        // information.
        const v = f(c);
        if (!Number.isNaN(v)) {
          fCalcs[c] = stringify(v);
        }
      }
    }
  }
  return calcs;
}

function stringify(v: number) {
  if (v == Number.NEGATIVE_INFINITY) {
    return '-Infinity';
  }

  if (v == Number.POSITIVE_INFINITY) {
    return 'Infinity';
  }

  return v;
}
