import { mmapiws, murmurhash3 } from './utils';

export interface Webgl {
  extensions?: string[];
  hash?: number;
}

// This is an extremely minimal use of WebGL. To get a good fingerprint,
// we may need to make more extensive use of it. It is painful to do
// without libraries though.
export function getWebgl(): Webgl {
  let gl: WebGLRenderingContext;
  const canvas = document.createElement('canvas');
  const props: Webgl = {};
  try {
    gl = (canvas.getContext('webgl') ||
      canvas.getContext('experimental-webgl')) as WebGLRenderingContext;
  } catch {}

  if (!gl) {
    return props;
  }

  try {
    props.extensions = gl.getSupportedExtensions();
  } catch {}
  try {
    if (!mmapiws.disableWebglHash) {
      props.hash = getWebglHash(gl, canvas);
    }
  } catch {}

  return props;
}

function getWebglHash(
  gl: WebGLRenderingContext,
  canvas: HTMLCanvasElement,
): number {
  /* eslint no-multi-str: 0 */
  const vShaderTemplate =
    '\
attribute vec2 attrVertex; \
varying vec2 varyinTexCoordinate; \
uniform vec2 uniformOffset; \
void main() { \
  varyinTexCoordinate = attrVertex + uniformOffset; \
  gl_Position = vec4(attrVertex, 0, 1); \
}';

  const fShaderTemplate =
    '\
precision mediump float; \
varying vec2 varyinTexCoordinate; \
void main() { \
  gl_FragColor = vec4(varyinTexCoordinate, 0, 1); \
}';

  const vertexPosBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexPosBuffer);

  const vertices = new Float32Array([
    // X, Y, Z,
    -0.2, -0.9, 0.0, 0.4, -0.26, 0.0, 0.0, 0.732134444, 0.0,
  ]);

  gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
  const vertexPosBufferItemSize = 3;
  const vertexPosBufferNumItems = 3;

  const program = gl.createProgram();
  const vshader = gl.createShader(gl.VERTEX_SHADER);
  gl.shaderSource(vshader, vShaderTemplate);
  gl.compileShader(vshader);

  const fshader = gl.createShader(gl.FRAGMENT_SHADER);
  gl.shaderSource(fshader, fShaderTemplate);
  gl.compileShader(fshader);

  gl.attachShader(program, vshader);
  gl.attachShader(program, fshader);
  gl.linkProgram(program);

  gl.useProgram(program);
  const programVertexPosAttrib = gl.getAttribLocation(program, 'attrVertex');
  const programOffsetUniform = gl.getUniformLocation(program, 'uniformOffset');
  // TODO: investigate whether this shoudl be set to something else. It was originally
  // a variable that wasn't set.
  gl.enableVertexAttribArray(0);
  gl.vertexAttribPointer(
    programVertexPosAttrib,
    vertexPosBufferItemSize,
    gl.FLOAT,
    false,
    0,
    0,
  );
  gl.uniform2f(programOffsetUniform, 1, 1);
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, vertexPosBufferNumItems);

  // Rather than using murmurhash, we could extract the PNG CRC, which
  // may be faster, but this is simpler for now.
  return murmurhash3(canvas.toDataURL(), 0);
}
