import { inThirdPartyIframe } from './utils';

export type ObjectProperties = Record<string, any>;

export async function getNavigatorProperties(): Promise<ObjectProperties> {
  const nav = objectProperties(navigator);

  // We use this for properties that haven't made it into the
  // navigator TypeScript type yet. Everything else should use
  // navigator directly so that we get type checking.
  const navigatorAny: any = navigator;
  const connection =
    navigatorAny.connection ||
    navigatorAny.mozConnection ||
    navigatorAny.webkitConnection;
  if (connection) {
    nav.connection = objectProperties(connection);
  }

  if ('getGamepads' in navigator) {
    try {
      nav.gamepads = Array.from(
        navigator
          .getGamepads()
          .filter((e) => !!e)
          .map((g) => g.id),
      );
    } catch {}
  }

  if (
    !inThirdPartyIframe() &&
    navigatorAny.keyboard &&
    navigatorAny.keyboard.getLayoutMap
  ) {
    (nav.keyboard ??= {}).layoutMap = Array.from(
      (await navigatorAny.keyboard.getLayoutMap()).entries(),
    );
  }

  if (navigator.mediaDevices) {
    if (navigator.mediaDevices.getSupportedConstraints) {
      (nav.mediaDevices ??= {}).supportedConstraints =
        navigator.mediaDevices.getSupportedConstraints();
    }

    if (navigator.mediaDevices.enumerateDevices) {
      // The IDs and labels do not seem stable, when set at all.
      (nav.mediaDevices ??= {}).enumerateDevices = (
        await navigator.mediaDevices.enumerateDevices()
      ).map((d) => d.kind);
    }
  }

  return nav;
}

export function getScreenProperties(): ObjectProperties {
  const properties: ObjectProperties = {};
  const order = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const property in window.screen) {
    if (typeof window.screen[property] !== 'object') {
      properties[property] = window.screen[property];
    }
    order.push(property);
  }
  properties.enumerationOrder = order;

  return properties;
}

export function objectProperties(obj: object): ObjectProperties {
  const order = [];
  const properties: ObjectProperties = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const property in obj) {
    // There are null and object properties which we do not include.
    //
    // Nulls appear in some document modes but not others in IE[8,10] so they
    // are not useful.
    //
    // We tried including plain objects, but that did not get anything
    // interesting in IE[8,11], Chrome 67, or Firefox 60 (the objects aren't
    // plain), so we don't bother.
    if (typeof obj[property] !== 'object' || isArray(obj[property])) {
      properties[property] = obj[property];
    }
    order.push(property);
  }
  properties.enumerationOrder = order;

  return properties;
}

// We cannot use "Array.isArray()" as some clients overwrite it with crazy
// things that do not work correctly. Instead we use the suggested polyfill
// function from MDN's isArray() page.
function isArray(a: any): boolean {
  return Object.prototype.toString.call(a) === '[object Array]';
}
