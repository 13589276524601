export interface Codecs {
  audio: { [key: string]: boolean };
  video: { [key: string]: boolean };
}

export function getCodecs(): Codecs {
  const videoTypes = [
    'video/mp4; codecs="avc1.42c00d"',
    'video/ogg; codecs="theora"',
    'video/webm; codecs="vorbis,vp8"',
    'video/webm; codecs="vorbis,vp9"',
    'video/mp2t; codecs="avc1.42E01E,mp4a.40.2"',
  ];
  const audioTypes = [
    'audio/mpeg',
    'audio/mp4; codecs="mp4a.40.2"',
    'audio/ogg; codecs="vorbis"',
    'audio/ogg; codecs="opus"',
    'audio/webm; codecs="vorbis"',
    'audio/wav; codecs="1"',
  ];

  return {
    audio: typeSupport(audioTypes),
    video: typeSupport(videoTypes),
  };
}

function typeSupport(mediaTypes: string[]): { [key: string]: boolean } {
  const supportedMediaTypes = {};
  for (let i = 0; i < mediaTypes.length; i++) {
    const mimeType = mediaTypes[i];
    if (window.MediaSource) {
      supportedMediaTypes[mimeType] =
        window.MediaSource.isTypeSupported(mimeType);
    }
  }
  return supportedMediaTypes;
}
