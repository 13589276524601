const fontsToTry = [
  'Abadi MT Condensed Light',
  'Adobe Fangsong Std',
  'Adobe Hebrew',
  'Adobe Ming Std',
  'Agency FB',
  'Arab',
  'Arabic Typesetting',
  'Arial Black',
  'Batang',
  'Bauhaus 93',
  'Bell MT',
  'Bitstream Vera Serif',
  'Bodoni MT',
  'Bookman Old Style',
  'Braggadocio',
  'Broadway',
  'Calibri',
  'Californian FB',
  'Castellar',
  'Casual',
  'Centaur',
  'Century Gothic',
  'Chalkduster',
  'Colonna MT',
  'Copperplate Gothic Light',
  'DejaVu LGC Sans Mono',
  'Desdemona',
  'DFKai-SB',
  'Dotum',
  'Engravers MT',
  'Eras Bold ITC',
  'Eurostile',
  'FangSong',
  'Forte',
  'Franklin Gothic Heavy',
  'French Script MT',
  'Gabriola',
  'Gigi',
  'Gisha',
  'Goudy Old Style',
  'Gulim',
  'GungSeo',
  'Haettenschweiler',
  'Harrington',
  'Hiragino Sans GB',
  'Impact',
  'Informal Roman',
  'KacstOne',
  'Kino MT',
  'Kozuka Gothic Pr6N',
  'Lohit Gujarati',
  'Loma',
  'Lucida Bright',
  'Lucida Fax',
  'Magneto',
  'Malgun Gothic',
  'Matura MT Script Capitals',
  'Menlo',
  'MingLiU-ExtB',
  'MoolBoran',
  'MS PMincho',
  'MS Reference Sans Serif',
  'News Gothic MT',
  'Niagara Solid',
  'Nyala',
  'Palace Script MT',
  'Papyrus',
  'Perpetua',
  'Playbill',
  'PMingLiU',
  'Rachana',
  'Rockwell',
  'Sawasdee',
  'Script MT Bold',
  'Segoe Print',
  'Showcard Gothic',
  'SimHei',
  'Snap ITC',
  'TlwgMono',
  'Tw Cen MT Condensed Extra Bold',
  'Ubuntu',
  'Umpush',
  'Univers',
  'Utopia',
  'Vladimir Script',
  'Wide Latin',
];

export function getFonts(): string[] {
  const fonts = getCanvasFonts();
  if (fonts !== null) {
    return fonts;
  }
  return getCssFonts();
}

function getCanvasFonts(): string[] {
  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D;
  try {
    canvas = document.createElement('canvas');
    ctx = canvas.getContext('2d');
  } catch {}

  if (!ctx) {
    return null;
  }

  const baseFonts = ['sans-serif', 'serif', 'monospace'];
  const baseFontMetrics = [];
  const fontSize = '72px';
  const testText = 'mmmmmmmmmmlli';

  for (let k = 0; k < baseFonts.length; k++) {
    ctx.font = fontSize + ' "' + baseFonts[k] + '"';
    baseFontMetrics[k] = ctx.measureText(testText);
  }

  const fonts = [];
  for (let i = 0; i < fontsToTry.length; i++) {
    const font = fontsToTry[i];

    let hasFont = false;
    for (let j = 0; j < baseFontMetrics.length; j++) {
      ctx.font = fontSize + ' "' + font + '", "' + baseFonts[j] + '"';
      const fontMetric = ctx.measureText(testText);
      if (
        fontMetric.width !== baseFontMetrics[j].width ||
        fontMetric.fontBoundingBoxAscent !==
          baseFontMetrics[j].fontBoundingBoxAscent
      ) {
        hasFont = true;
        break;
      }
    }
    if (hasFont) {
      fonts.push(font);
    }
  }
  return fonts;
}

function getCssFonts(): string[] {
  const fonts = [];
  const fontDetector = new Detector();

  for (let i = 0; i < fontsToTry.length; i++) {
    const font = fontsToTry[i];

    if (fontDetector.detect(font)) {
      fonts.push(font);
    }
  }
  fontDetector.finish();
  return fonts;
}

/*!
 * Based on Detector function (v.0.3) from:
 * Author : Lalit Patel
 * Website: http://www.lalit.org/lab/javascript-css-font-detect/
 * License: Apache Software License 2.0
 *          http://www.apache.org/licenses/LICENSE-2.0
 */
function Detector() {
  const baseFonts = ['monospace', 'sans-serif', 'serif'];
  const baseFontSizes = [];
  const testString = 'mmmmmmmmmmlli';
  const testSize = '72px';
  const body = document.body;
  const span = document.createElement('span');
  const style = span.style;

  style.fontSize = testSize;
  style.visibility = 'hidden';
  span.innerText = testString;
  body.appendChild(span);

  const calculateFontSize = function (name: string): {
    height: number;
    width: number;
  } {
    style.fontFamily = name;

    return {
      height: span.offsetHeight,
      width: span.offsetWidth,
    };
  };

  for (let i = 0; i < baseFonts.length; i++) {
    baseFontSizes[i] = calculateFontSize(baseFonts[i]);
  }

  this.detect = function (font: string): boolean {
    for (let j = 0; j < baseFontSizes.length; j++) {
      const fontSize = calculateFontSize('"' + font + '",' + baseFonts[j]);
      const baseFontSize = baseFontSizes[j];
      if (
        fontSize.height !== baseFontSize.height ||
        fontSize.width !== baseFontSize.width
      ) {
        return true;
      }
    }
    return false;
  };

  this.finish = function (): void {
    body.removeChild(span);
  };
}
