interface MIMEType {
  description: string;
  suffixes: string;
  type: string;
}

export interface DevicePlugin {
  name: string;
  filename: string;
  description: string;
  mimeTypes?: MIMEType[];
  version?: string;
}

// This list is a mostly random sample of plugins we saw on proxies.
const pluginsToTry = [
  '4game',
  'AdblockPlugin',
  'AdobeExManCCDetect',
  'AdobeExManDetect',
  'Alawar NPAPI utils',
  'Aliedit Plug-In',
  'Alipay Security Control 3',
  'AliSSOLogin plugin',
  'AmazonMP3DownloaderPlugin',
  'AOL Media Playback Plugin',
  'AppUp',
  'ArchiCAD',
  'AVG SiteSafety plugin',
  'Babylon ToolBar',
  'Battlelog Game Launcher',
  'BitCometAgent',
  'Bitdefender QuickScan',
  'BlueStacks Install Detector',
  'CatalinaGroup Update',
  'Citrix ICA Client',
  'Citrix online plug-in',
  'Citrix Receiver Plug-in',
  'Coowon Update',
  'DealPlyLive Update',
  'Default Browser Helper',
  'DivX Browser Plug-In',
  'DivX Plus Web Player',
  'DivX VOD Helper Plug-in',
  'doubleTwist Web Plugin',
  'Downloaders plugin',
  'downloadUpdater',
  'eMusicPlugin DLM6',
  'ESN Launch Mozilla Plugin',
  'ESN Sonar API',
  'Exif Everywhere',
  'Facebook Plugin',
  'File Downloader Plug-in',
  'FileLab plugin',
  'FlyOrDie Games Plugin',
  'Folx 3 Browser Plugin',
  'FUZEShare',
  'GDL Object Web Plug-in 16.00',
  'GFACE Plugin',
  'Ginger',
  'Gnome Shell Integration',
  'Google Earth Plugin',
  'Google Earth Plug-in',
  'Google Gears 0.5.33.0',
  'Google Talk Effects Plugin',
  'Google Update',
  'Harmony Firefox Plugin',
  'Harmony Plug-In',
  'Heroes & Generals live',
  'HPDetect',
  'Html5 location provider',
  'IE Tab plugin',
  'iGetterScriptablePlugin',
  'iMesh plugin',
  'Kaspersky Password Manager',
  'LastPass',
  'LogMeIn Plugin 1.0.0.935',
  'LogMeIn Plugin 1.0.0.961',
  'Ma-Config.com plugin',
  'Microsoft Office 2013',
  'MinibarPlugin',
  'Native Client',
  'Nitro PDF Plug-In',
  'Nokia Suite Enabler Plugin',
  'Norton Identity Safe',
  'npAPI Plugin',
  'NPLastPass',
  'NPPlayerShell',
  'npTongbuAddin',
  'NyxLauncher',
  'Octoshape Streaming Services',
  'Online Storage plug-in',
  'Orbit Downloader',
  'Pando Web Plugin',
  'Parom.TV player plugin',
  'PDF integrado do WebKit',
  'PDF-XChange Viewer',
  'PhotoCenterPlugin1.1.2.2',
  'Picasa',
  'PlayOn Plug-in',
  'QQ2013 Firefox Plugin',
  'QQDownload Plugin',
  'QQMiniDL Plugin',
  'QQMusic',
  'RealDownloader Plugin',
  'Roblox Launcher Plugin',
  'RockMelt Update',
  'Safer Update',
  'SafeSearch',
  'Scripting.Dictionary',
  'SefClient Plugin',
  'Shell.UIHelper',
  'Silverlight Plug-In',
  'Simple Pass',
  'Skype Web Plugin',
  'SumatraPDF Browser Plugin',
  'Symantec PKI Client',
  'Tencent FTN plug-in',
  'Thunder DapCtrl NPAPI Plugin',
  'TorchHelper',
  'Unity Player',
  'Uplay PC',
  'VDownloader',
  'Veetle TV Core',
  'VLC Multimedia Plugin',
  'Web Components',
  'WebKit-integrierte PDF',
  'WEBZEN Browser Extension',
  'Wolfram Mathematica',
  'WordCaptureX',
  'WPI Detector 1.4',
  'Yandex Media Plugin',
  'Yandex PDF Viewer',
  'YouTube Plug-in',
  'zako',
];

export function getPlugins(): DevicePlugin[] {
  // We put the plugins in an array instead of a map as order can
  // reveal installation order on some browsers.
  const plugins = [];
  const seen = {};

  for (let i = 0; i < navigator.plugins.length; i++) {
    const plugin = navigator.plugins[i];

    // We skip Flash with Chrome as it has been causing fingerprint
    // differences between subdomains. In particular, it is sometimes
    // present on one subdomain but not another, even though the content
    // settings are the same. Enumerating navigator.plugins is mostly
    // worthless nowadays anyway.
    if (
      plugin.name === 'Shockwave Flash' &&
      navigator.userAgent.indexOf('Chrome') > -1
    ) {
      continue;
    }
    seen[plugin.name] = 1;

    plugins.push(getPluginProperties(plugin));
  }

  // Firefox 29+ only displays a small subset of plugins while
  // enumerating to reduce the browser's entropy
  for (let i = 0; i < pluginsToTry.length; i++) {
    const name = pluginsToTry[i];
    if (!seen[name]) {
      const plugin = navigator.plugins[name];
      if (plugin) {
        plugins.push(getPluginProperties(plugin));
      }
    }
  }
  return plugins;
}

function getPluginProperties(plugin: Plugin): DevicePlugin {
  const p: DevicePlugin = {
    name: plugin.name,
    filename: plugin.filename.toLowerCase(),
    description: plugin.description,
  };

  if (typeof (plugin as any).version !== 'undefined') {
    p.version = (plugin as any).version;
  }

  p.mimeTypes = [];
  for (let j = 0; j < plugin.length; j++) {
    const mime = plugin[j];

    p.mimeTypes.push({
      description: mime.description,
      suffixes: mime.suffixes,
      type: mime.type,
    });
  }
  return p;
}
