import { ObjectProperties, objectProperties } from './properties';
import { timer } from './utils';

export interface SpeechSynthesisProperties {
  voices: ObjectProperties[];
}

export async function getSpeechSynthesis(): Promise<SpeechSynthesisProperties> {
  if (!window.speechSynthesis) {
    return null;
  }

  try {
    const voices = await getVoices();
    return {
      voices: voices
        // We take at most 20 elements as this can be very large in Firefox.
        .slice(0, 20)
        .map((v) => objectProperties(v)),
    };
  } catch {
    return null;
  }
}

// This is a pretty annoying API as getVoices will return an empty
// list right away as the browser hasn't loaded it yet. We construct
// our own async version of it.
async function getVoices() {
  const voices = speechSynthesis.getVoices();
  if (voices.length) {
    return voices;
  }

  await Promise.race([
    new Promise((r) =>
      speechSynthesis.addEventListener('voiceschanged', r, { once: true }),
    ),
    // Wait up to 50 ms for the voices.
    timer(50),
  ]);

  return speechSynthesis.getVoices();
}
